export const LANGUAGE = {
  ua: {
    HomePageTitle: "HAB buro",
    SubHomePage: "знаходимо досконалі рішення для ваших задач",
    SelectedProjects: "Проєкти",
    buttonConversion: "Зв'язатись",
    headerPortfolio: "проєкти",
    homaPageProject: "Обрані проєкти",
    headerAboutUs: "Про нас",
    headerContacts: "Контакти",
    UA: "UA",
    EU: "EN",
    exterior: "архітектура",
    interior: "інтер'єр",
    landscape: "ландшафт",
    all: "усі",
    mainHomePage:
      "Наша команда працює над житловими, комерційними та корпоративними проектами в Україні та Європі. Ми віримо, що краса та майстерність — це індивідуальність, яка складається з деталей.",
    viewAll: "подивитись усі",
    letsTalk: "Поспілкуємось?",
    joinTeam: "Приєднатись до команди",
    nameLabel: "Ім'я та прізвище*",
    phoneLabel: "Телефон*",
    commentLabel: "Коментар",
    emailLabel: "Пошта*",
    linkLabel: "Посилання на резюме",
    sendRequest: "Оформлення заявки",
    namePlaceholder: "Введіть ваше ім'я та прізвище",
    emailPlaceholder: "Введіть вашу пошту",
    phonePlaceholder: "+380956954384",
    commentPlaceholder: "Введіть ваш коментар",
    linkPlaceholder: "Введіть ваше посилилання на резюме",
    address: "Україна, м.Чернівці",
    whoWeAre: "Хто ми",
    whatWeDo: "Що ми робимо",
    joinOurBuro: "Приєднатись до нас",
    team: "Наша команда",
    whoWeAreText:
      "HAB buro – студія архітектури та дизайну інтер’єру, заснована у 2022 році в Чернівцях, Україна. Це простір, де поєднуються ідеї та рішення.\n\nНаша команда працює над житловими, комерційними та корпоративними проектами в Україні та Європі. \n\n Ми віримо, що краса та майстерність – це індивідуальність, яка складається з деталей. Деталі перетворюють малюнки та зображення на реальне втілення, яке має надихати та виконувати функцію.",
    whatWeDo1: "Ландшафтне проектування",
    whatWeDo2: "Архітектурне проектування",
    whatWeDo3: "Дизайн інтер'єру",
    whatWeDo4: "Реконструкція, реставрація та оновлення",
    whatWeDo5: "Контроль, підтримка та управління проектами",
    joinOurBuroText:
      "Ми завжди шукаємо захоплених амбітних людей. Динамічна нагороджена команда для вашого розвитку. Давайте разом формувати майбутнє дизайну!\n\nМи відкриті до нових можливостей і раді вітати нових талантів у нашій команді. Заповніть форму, і наша команда зв’яжеться з вами найближчим часом!",
    contacts: "Контакти",
    visitUs: "Завітайте до нас",
    callUs: "Зателефонувати",
    writeUs: "Написати нам",
    nameError: "будь ласка введіть ім'я",
    phoneError: "будь ласка введіть телефон",
    emailError: "будь ласка введіть пошту",
    cvlinkError: "будь ласка введіть коректну адресу",
    commentError: "будь ласка коментар",
    contactAddress: "Адреса",
    contactPhone: "Телефон",
    contactEmail: "Пошта",
    tour: "3D тур",
    tourCategory: "3D тур",
    otherProject: "Інші проєкти",
    projectName: "Проєкт",
    projectCategory: "Категорія",
    projectLocation: "Локація",
    projectYear: "Рік",
    projectArea: "Площа",
    home: "домашня сторінка",
  },
  eu: {
    HomePageTitle: "HAB buro",
    SubHomePage: "explore the new collection",
    SelectedProjects: "Portfolio",
    buttonConversion: "conversation",
    headerPortfolio: "Portfolio",
    homaPageProject: "Selected Projects",
    headerAboutUs: "About us",
    headerContacts: "Contacts",
    UA: "UA",
    EU: "EN",
    exterior: "architecture",
    interior: "interior",
    landscape: "landscape",
    all: "all",
    mainHomePage:
      "HAB buro is a space where ideas and solutions come together. We believe that beauty and craftsmanship is an individuality that is made up of details. Details transform drawings and images into a tangible embodiment that should inspire and deliver function.",
    viewAll: "view all",
    letsTalk: "let's talk?",
    joinTeam: "join the team",
    nameLabel: "FIRST NAME & LAST NAME*",
    phoneLabel: "Phone*",
    commentLabel: "Comment*",
    emailLabel: "e-mail*",
    linkLabel: "link cv*",
    sendRequest: "send request",
    namePlaceholder: "enter username",
    emailPlaceholder: "enter your e-mail",
    phonePlaceholder: "+380956954384",
    commentPlaceholder: "enter your comment (optional)",
    linkPlaceholder: "enter you CV-link",
    address: "Ukraine, c.Chernivtsi",
    whoWeAre: "Who we are",
    whatWeDo: "What we do",
    joinOurBuro: "Join the hab buro team",
    team: "The Team",
    whoWeAreText:
      "HAB buro is an architecture and interior design studio founded in 2022 in Chernivtsi, Ukraine. It is a space where ideas and solutions come together.\n\n Our team works on residential, commercial, and corporate projects in Ukraine and Europe.We believe that beauty and craftsmanship is an individuality that is made up of details.\n\n Details transform drawings and images into a tangible embodiment that should inspire and deliver function.",
    whatWeDo1: "Passive house, active house, nZEB design",
    whatWeDo2: "Architectural design",
    whatWeDo3: "Interior design",
    whatWeDo4: "Reconstruction, restoration and renovation",
    whatWeDo5: "Supervision, support and project management",
    joinOurBuroText:
      "We are always looking for enthusiastic ambitious people. Dynamic award-winning team for your growth. Let’s shape the future of design together!\n\n We are a open to new opportunities and happy to welcome new talents to our team. Fill out the form, and our team member will reach out to you shortly!",
    contacts: "Contacts",
    visitUs: "visit us",
    callUs: "call us",
    writeUs: "write us",
    nameError: "please enter the name",
    phoneError: "please enter the phone format",
    emailError: "please enter the email",
    cvlinkError: "please enter the correct URL",
    commentError: "please enter the comment",
    contactAddress: "Address",
    contactPhone: "Phone",
    contactEmail: "E-mail",
    tour: "3D tour",
    tourCategory: "3D tour",
    otherProject: "other projects",
    projectName: "Project",
    projectCategory: "Catagory",
    projectLocation: "Location",
    projectYear: "Year of the project",
    projectArea: "Area",
    home: "Home",
  },
};
